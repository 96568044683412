import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "./fsq.css"
import fetch from "node-fetch"

export default class FirstSessionQuestionnairePage extends React.Component {
  constructor() {
    super()
    // Names of Form Fields
    this.state = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      age: "",
      birthday: "",
      occupation: "",
      beforeWeBegin: "",
      interestedIn: "",
      threeGoals: "",
      threeChanges: "",
      mostImportant: "",
      makeYouHappy: "",
      fivePuttingUpWith: "",
      challengesToGoals: "",
      outsideSessionWork: "",
      judgementOfSessions: "",
      howHappy: 0,
      whatWouldYouLike: "",
      wonders: "",
      qsToAdd: "",
      sourceOfAcquisition: "",
      submitted: false,
      importantMotivate: false,
      holdingMeBack: false,
      encourageSupport: false,
      defineGoals: false,
      helpIdentify: false,
      challenge: false,
      feedback: false,
      accountability: false,
      insights: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }
  handleInputChange(event) {
    const target = event.target
    let value = target.type === "checkbox" ? target.checked : target.value
    let name = target.name

    const whatWouldYouLikeOptions = [
      "importantMotivate",
      "holdingMeBack",
      "encourageSupport",
      "defineGoals",
      "helpIdentify",
      "challenge",
      "feedback",
      "accountability",
      "insights",
    ]

    if (whatWouldYouLikeOptions.includes(name)) {
      this.setState({
        [name]: !this.state[name],
      })

      value = `${name} ${this.state.whatWouldYouLike}`
      name = "whatWouldYouLike"
    }

    this.setState({
      [name]: value,
    })
  }

  async handleFormSubmit(event) {
    event.preventDefault()

    const data = JSON.stringify(this.state);

    const response = await fetch("/.netlify/functions/create-client", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state),
    }).then(res => res.json()).catch(err => console.error(err));

    this.setState({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      birthday: "",
      occupation: "",
      beforeWeBegin: "",
      interestedIn: "",
      threeGoals: "",
      threeChanges: "",
      mostImportant: "",
      makeYouHappy: "",
      fivePuttingUpWith: "",
      challengesToGoals: "",
      outsideSessionWork: "",
      judgementOfSessions: "",
      howHappy: 0,
      whatWouldYouLike: "",
      wonders: "",
      qsToAdd: "",
      sourceOfAcquisition: "",
      submitted: "true",
      importantMotivate: false,
      holdingMeBack: false,
      encourageSupport: false,
      defineGoals: false,
      helpIdentify: false,
      challenge: false,
      feedback: false,
      accountability: false,
      insights: false,
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="First Session Questionnaire | Kenneth Taylor | Lifestyle Coach" />
        <section id="questionnaire">
          <div className="container">
            <h1>First Session Questionnaire</h1>
            <p>
              I would like to get more concrete details from you in your own
              words before we start our coaching sessions. This will help us
              move quickly towards creating a life you love. Please complete
              this form and submit it to back to me before our first session
              together. Thank you!
            </p>
            <form onSubmit={event => this.handleFormSubmit(event)}>
              <input
                placeholder="First Name"
                value={this.state.firstName}
                onChange={event => this.handleInputChange(event)}
                name="firstName"
              />
              <input
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={event => this.handleInputChange(event)}
                name="lastName"
              />
              <input
                placeholder="Phone Number"
                value={this.state.phoneNumber}
                onChange={event => this.handleInputChange(event)}
                name="phoneNumber"
              />
              <input
                placeholder="Email"
                value={this.state.email}
                onChange={event => this.handleInputChange(event)}
                name="email"
              />
              <input
                placeholder="Birthday"
                value={this.state.birthday}
                onChange={event => this.handleInputChange(event)}
                name="birthday"
              />
              <input
                placeholder="Occupation"
                value={this.state.occupation}
                onChange={event => this.handleInputChange(event)}
                name="occupation"
              />
              <hr className="divider" />
              <textarea
                name="beforeWeBegin"
                placeholder="Before we begin our coaching sessions, what do I need to know about you?"
                value={this.state.beforeWeBegin}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="interestedIn"
                placeholder="Why are you interested in coaching?"
                value={this.state.interestedIn}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="threeGoals"
                placeholder="What 3 goals do you want to achieve within the next 3 months?"
                value={this.state.threeGoals}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="threeChanges"
                placeholder="What are the 3 biggest changes you want to make in your life over the next 5 years?"
                value={this.state.threeChanges}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="mostImportant"
                placeholder="What is most important to you in your life and why?"
                value={this.state.mostImportant}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="makeYouHappy"
                placeholder="What are the things that make you happy?"
                value={this.state.makeYouHappy}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="fivePuttingUpWith"
                placeholder="List 5 things that you feel you are ‘putting up with’ right now?"
                value={this.state.fivePuttingUpWith}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="challengesToGoals"
                placeholder="What are your challenges preventing you from reaching your goals?"
                value={this.state.challengesToGoals}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="outsideSessionWork"
                placeholder="How much work are you able to do outside of our sessions?"
                value={this.state.outsideSessionWork}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <textarea
                name="judgementOfSessions"
                placeholder="How will you judge if you are receiving value from the coaching process?"
                value={this.state.judgementOfSessions}
                onChange={event => this.handleInputChange(event)}
              ></textarea>
              <div>
                <p>What would you like from me during your sessions:</p>
                <div className="checkboxes">
                  <div>
                    <input
                      type="checkbox"
                      id="clarity"
                      name="clarity"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.clarity}
                    />
                    <label>Gaining clarity of issues</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="importantMotivate"
                      name="importantMotivate"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.importantMotivate}
                    />
                    <label>
                      Understanding what is important/what motivates me
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="holdingMeBack"
                      name="holdingMeBack"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.holdingMeBack}
                    />
                    <label>
                      Exploring and understanding what is holding me back
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="encourageSupport"
                      name="encourageSupport"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.encourageSupport}
                    />
                    <label>Providing encouragement and support</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="defineGoals"
                      name="defineGoals"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.defineGoals}
                    />
                    <label>Helping define goals</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="helpIdentify"
                      name="helpIdentify"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.helpIdentify}
                    />
                    <label>Helping to identify action and next steps</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="challenge"
                      name="challenge"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.challenge}
                    />
                    <label>Challenging you with difficult questions</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="feedback"
                      name="feedback"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.feedback}
                    />
                    <label>Providing honest and direct feedback</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="accountability"
                      name="accountability"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.accountability}
                    />
                    <label>Making you accountable for your goals</label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      id="insights"
                      name="insights"
                      onChange={event => this.handleInputChange(event)}
                      checked={this.state.insights}
                    />
                    <label>
                      Gaining an insight into who I am, my strengths,
                      capabilities and potential
                    </label>
                  </div>
                </div>
              </div>
              <hr className="" />
              <textarea
                name="wonders"
                placeholder="Regarding coaching, what are you still wondering about?"
                value={this.state.wonders}
                onChange={this.handleInputChange}
              ></textarea>
              <textarea
                name="qsToAdd"
                placeholder="What questions would you suggest I add to this survey?"
                value={this.state.qsToAdd}
                onChange={this.handleInputChange}
              ></textarea>
              <textarea
                name="sourceOfAcquisition"
                placeholder="How did you hear about me?"
                value={this.state.sourceOfAcquisition}
                onChange={this.handleInputChange}
              ></textarea>
              <input type="submit" value="Submit" />
            </form>
          </div>
        </section>
      </Layout>
    )
  }
}
